<template>
  <el-select
    v-model="address"
    clearable
    filterable
    :multiple="multiple"
    placeholder="Выберите адрес"
    :disabled="disabled"
    @change="onChange"
  >
    <el-option-group
      v-for="group in groupsAddress"
      :key="group.id"
      :label="group.title"
    >
      <el-option
        v-for="item in group.addreses"
        :key="item.id"
        :label="item.title"
        :value="item.id"
      >
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from 'lodash';
export default {
  name: 'AddressSelect',
  props: {
    value: {
      type: [String, Number, Array],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    items: {
      type: [Array, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('addresses', ['addresses']),
    address: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    groupsAddress() {
      const groups = {};

      (this.items || this.addresses).forEach((addr) => {
        if (!groups[addr.object_directory.id])
          groups[addr.object_directory.id] = {
            addreses: [],
            ...addr.object_directory,
          };
        groups[addr.object_directory.id].addreses.push(addr);
      });

      return groups;
    },
  },
  methods: {
    onChange: debounce(function () {
      this.$emit('change');
    }, 1000),
  },
};
</script>

<style lang="scss" scoped></style>
