<template>
  <el-dialog
    :title="label"
    :visible="isModalOpen"
    width="640px"
    center
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <component
      :is="component"
      :content="content"
      :disabled="disabled"
    ></component>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import * as Forms from './Forms';

export default {
  name: 'Modal',
  components: { ...Forms },
  computed: {
    ...mapState('modal', [
      'isModalOpen',
      'component',
      'content',
      'options',
      'disabled',
    ]),
    ...mapGetters('modal', ['component', 'label']),
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handleClose() {
      this.$store.dispatch('modal/close');
    },
  },
};
</script>

<style lang="scss" scoped></style>
