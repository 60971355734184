<template>
  <div>
    <el-dialog
      title="Отсутствует подключение к интренету"
      :visible.sync="online"
      width="30%"
      center
      :show-close="false"
      lock-scroll
      :close-on-click-modal="false"
    >
      <el-alert type="warning" :closable="false">
        <slot name="title">
          <div class="svg-icon" v-html="$icons['wifi-off']"></div>
          <span
            >Для корректной работы системы необходимо<br />
            стабильное интернет подключение</span
          >
        </slot>
      </el-alert>
    </el-dialog>
    <div class="austronaut" v-if="online"></div>
  </div>
</template>

<script>
export default {
  name: 'Online',
  data() {
    return {
      online: false,
    };
  },
  created() {
    this.online = !navigator.onLine;
    window.addEventListener('online', () => (this.online = false));
    window.addEventListener('offline', () => (this.online = true));
  },
};
</script>

<style lang="scss" scoped>
.austronaut {
  background-image: url(@/assets/img/astronaut.png);
  height: 70px;
  width: 70px;
  position: fixed;
  z-index: 9999;
  top: -50px;
  left: -50px;
  background-size: contain;

  animation-name: focus-in-contract;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;

  /* shorthand
		animation: focus-in-contract 100s linear 0s infinite normal forwards;*/
}

@keyframes focus-in-contract {
  0% {
    transform: rotate(0);
    top: -100px;
    left: -100px;
  }
  10% {
    transform: rotate(2000deg);
    top: 30%;
    left: 30%;
  }
  100% {
    transform: rotate(1000deg);
    top: 90%;
    left: -100px;
  }
}
</style>
