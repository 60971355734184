<template>
  <div class="header">
    <div class="__left">
      <Breadcrumbs />
    </div>
    <div class="header__navigation">
      <router-link
        class="header__navigation-link __right"
        :to="{ name: 'profile' }"
        >Настройки аккаунта</router-link
      >
    </div>
    <div class="user">
      <div class="user__info">
        <div class="user__info-name">{{ name }}</div>
        <div class="user__info-email">{{ profile?.email || '–' }}</div>
      </div>
      <div class="user-logout" @click="logout">
        <div class="svg-icon" v-html="$icons['log-out']"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Breadcrumbs } from './Breadcrumbs';
export default {
  name: 'Header',
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapState(['profile']),

    name() {
      const name = [];
      if (this.profile?.family_name) name.push(this.profile.family_name);
      if (this.profile?.given_name) name.push(this.profile.given_name);
      if (this.profile?.middle_name) name.push(this.profile.middle_name);

      return name.join(' ');
    },
  },
  methods: {
    logout() {
      this.$confirm(
        'Вы действительно хотите завершить сессию текущего пользователя?',
        '',
        {
          confirmButtonText: 'Выйти',
          cancelButtonText: 'Отмена',
        }
      )
        .then(() => {
          this.$store.dispatch('logout');
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 60px;
  width: calc(100% + 64px);
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  border-top: none;
  margin-bottom: 24px;
  background-color: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  padding: 0 32px;
  margin-left: -32px;
  position: sticky;
  top: 0;
  z-index: 990;

  .header__navigation {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 1;
    margin-right: 24px;

    &-link {
      height: 36px;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 18px;
      transition: 0.25s;
      border-radius: 4px;
      &.__right {
        margin-left: auto;
      }
      &:hover,
      &.router-link-active {
        background-color: #f5f5f5;
        color: var(--color-blue);
      }
    }
  }

  .user {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    &__info {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-end;

      &-name {
        font-size: 14px;
        font-weight: 500;
      }

      &-email {
        font-size: 13px;
        color: #8392a5;
      }
    }
    &-logout {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      align-items: center;
      width: 32px;
      margin-left: 16px;
      height: 32px;
      border-radius: 4px;
      transition: 0.25s;
      color: #c5c5d8;
      cursor: pointer;
      &:hover {
        background-color: #f5f5f5;
        color: #505061;
      }
      .svg-icon {
        height: 20px;
        width: 20px;
      }
    }
  }
}
</style>
