<template>
  <div class="layout">
    <Wrapper>
      <div class="brand__block">
        <div class="brand">
          <div class="brand-text">DataHi. from Itillect IG</div>
          <div class="brand-subtext">Tools designed for your business</div>
        </div>
      </div>
      <router-view />
    </Wrapper>
  </div>
</template>

<script>
import { Wrapper } from './Wrapper';

export default {
  name: 'Default',
  components: {
    Wrapper,
  },
};
</script>

<style lang="scss" scoped>
#app .layout::v-deep {
  background-image: url('@/assets/img/bg.jpg');
  background-size: cover;
  background-position: center;
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
    min-height: 480px;
  }
}

.brand__block {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  color: #fff;
}
.brand {
  &-text {
    font-size: 42px;
    font-weight: 500;
  }

  &-subtext {
    font-size: 19px;
    font-weight: 300;
  }
}
</style>
