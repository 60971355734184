<template>
  <section>
    <div class="wrap">
      <div class="content">
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Wrapper',
};
</script>

<style lang="scss">
.page {
  padding: 48px 0 32px;
  display: flex;
  flex-grow: 1;

  &.page-transparent {
    box-shadow: none;
    background-color: transparent;
  }
  &-block {
    width: 100%;
  }
  &-inner {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04);
    width: 100%;
    max-width: 980px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }
}
</style>
