<template>
  <div class="layout" :class="{ load: isLoading }">
    <Menu />
    <Wrapper>
      <Header />
      <router-view />
    </Wrapper>
    <Modal />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { Wrapper } from './Wrapper';
import { Menu } from '@/components/Menu';
import { Modal } from '@/components/Modal';

import { Header } from '@/components/Header';

export default {
  name: 'Default',
  components: {
    Wrapper,
    Menu,
    Modal,
    Header,
  },
  computed: {
    ...mapState(['isLoading']),
  },
};
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: stretch;

  background-color: #f8f9fa;

  section {
    padding: 0 32px;
  }
}
</style>
