import { render, staticRenderFns } from "./PaymentForm.vue?vue&type=template&id=6f67ddaa&scoped=true&"
import script from "./PaymentForm.vue?vue&type=script&lang=js&"
export * from "./PaymentForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f67ddaa",
  null
  
)

export default component.exports