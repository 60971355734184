import Vue from 'vue';
import accounting from 'accounting';
import moment from 'moment';

Vue.filter('money', (value) => {
  return accounting.formatMoney(parseFloat(value), {
    symbol: '₽',
    format: '%v %s',
    decimal: '.',
    thousand: ' ',
    precision: 2,
  });
});

Vue.filter('count', (value) => {
  return accounting.formatNumber(value, 0, ' ', '.');
});
Vue.filter('payDay', (day) => {
  if (isNaN(+day)) return day;
  return moment(day, 'DD').isSameOrAfter(moment({ hour: 0 }))
    ? moment(day, 'DD').format('DD.MM.YYYY')
    : moment(day, 'DD').add(1, 'months').format('DD.MM.YYYY');
});

Vue.filter('closesPay', (day) => {
  const current = Number(moment().format('D'));
  let dateMut = moment().set('date', day);
  if (current == day) return 'Сегодня';
  if (current < day && day - current == 1) return 'Завтра';
  if (current > day) dateMut.add(1, 'M');

  const days = dateMut.diff(moment(), 'days');

  return `До платежа ${days} ${num_word(days, ['день', 'дня', 'дней'])}`;
});

Vue.filter('closesDate', (day) => {
  const current = Number(moment().format('D'));
  let dateMut = moment().set('date', day);
  if (current == day) return 'Сегодня';
  if (current < day && day - current == 1) return 'Завтра';
  if (current > day) dateMut.add(1, 'M');

  const days = dateMut.diff(moment(), 'days');

  return `Через ${days} ${num_word(days, ['день', 'дня', 'дней'])}`;
});

Vue.filter('phone', (value) => {
  if (!value || value === '–') return value;
  const phone = value.replace(/[^-0-9-.]/, '');
  if (!phone) return value;
  const regex = /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/gm;
  const subst = `+$1 ($2) $3-$4-$5`;
  const result = phone.replace(regex, subst);

  return result;
});

Vue.filter('fnull', (val) => (val < 10 ? `0${val}` : val));

Vue.filter('num_word', num_word);

function num_word(value, words) {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}

Vue.filter('fullToShort', (value) => {
  if (!value || value === '–') return value;
  var s = value.split(' ');
  let n = s[0] + ' ';
  if (s[1])
    n +=
      s[1].length > 1
        ? s[1].substr(0, 1).toUpperCase() + '.'
        : s[1].toUpperCase() + '.';
  if (s[2])
    n +=
      s[2].length > 1
        ? s[2].substr(0, 1).toUpperCase() + '.'
        : s[2].toUpperCase() + '.';

  return n;
});
