<template>
  <el-form
    class="object-form"
    ref="form"
    :model="form"
    :rules="rules"
    :disabled="load"
    @validate="onValidate"
  >
    <el-form-item label="Площадка" prop="title">
      <el-input
        v-model="form.title"
        placeholder="Наименование площадки"
      ></el-input>
    </el-form-item>
    <el-form-item label="Бригадир" prop="foreman">
      <el-select
        v-model="form.foreman"
        clearable
        placeholder="Выберите бригадира"
      >
        <el-option
          v-for="{ id, family_name, given_name, middle_name } in foremans"
          :key="id"
          :label="`${family_name} ${given_name} ${middle_name}`"
          :value="id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Комментарий" prop="comment">
      <el-input
        v-model="form.comment"
        type="textarea"
        placeholder="Комментарий"
      ></el-input>
    </el-form-item>
    <el-button
      type="success"
      @click="save"
      v-loading="load"
      :disabled="!isFormValid || !changed"
    >
      {{ options.buttonLabel }}
    </el-button>
  </el-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ObjectForm',
  props: {
    content: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      form: {
        title: '',
        comment: '',
        foreman: null,
      },
      rules: {
        title: [{ required: true, message: 'Поле не может быть пустым' }],
      },
      isValid: false,
      validFlags: {
        title: false,
      },
      load: false,
      changed: true,
    };
  },
  computed: {
    ...mapState('modal', ['options']),
    ...mapGetters('users', ['foremans']),
    isFormValid() {
      return Object.values(this.validFlags).every((flag) => flag);
    },
  },
  created() {
    if (!this.content) return;

    this.changed = false;
    Object.entries(this.content).forEach(([key, value]) => {
      if (this.form[key] !== undefined) this.form[key] = value;
    });
    this.$watch('form', () => (this.changed = true), { deep: true });
  },
  mounted() {
    if (this.content) this.$refs.form.validate();
  },
  methods: {
    onValidate(field, isValid) {
      this.validFlags[field] = isValid;
    },
    save() {
      this.load = true;
      if (this.content) this.form.id = this.content.id;
      this.$store
        .dispatch('modal/resolve', { data: this.form })
        .finally(() => (this.load = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.object-form::v-deep {
  .el-form-item__label {
    text-align: left;
  }
}
</style>
