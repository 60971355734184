<template>
  <div class="loader">
    <div class="h1wrap">
      <h1 class="loading loading__animation">
        <span>L</span>
        <span>i</span>
        <span>g</span>
        <span>h</span>
        <span>t</span>
        <span>L</span>
        <span>o</span>
        <span>g</span>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h1 {
  line-height: 1.666em;
  font-size: 80px;
  font-weight: 900;
  letter-spacing: 0.0225em;
  opacity: 0.85;
  color: #2f353b;

  transform: scale(1);
}

@keyframes superscript {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0em);
  }
}
.loading__animation {
  span {
    animation: loading 1.2s infinite alternate;
    @for $i from 1 through 10 {
      &:nth-child(#{$i + 1}) {
        animation-delay: #{$i * 0.2}s;
      }
    }
  }
}
@keyframes loading {
  0% {
    filter: blur(0);
    opacity: 1;
  }
  100% {
    filter: blur(5px);
    opacity: 0.2;
  }
}
</style>
