import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './store';

Vue.use(VueRouter);

const useComponent = (component) => () => import(`./views/${component}.vue`);
const useChildrenComponent = (component) => () =>
  import(`./components/${component}.vue`);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: 'report',
    meta: {
      auth: true,
    },
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: useComponent('dashboard'),
  //   meta: {
  //     title: 'Дашборд',
  //     auth: true,
  //     menu: true,
  //     icon: 'table',
  //     label: 'Дашборд',
  //   },
  // },
  {
    path: '/auth',
    name: 'auth',
    component: useComponent('Auth/auth'),
    meta: {
      layout: 'auth',
      title: 'Авторизация',
    },
  },
  {
    path: '/report',
    name: 'report',
    component: useComponent('report'),
    meta: {
      title: 'Отчет',
      auth: true,
      menu: true,
      icon: 'pie-chart',
      label: 'Отчет',
      menuGroup: 'Отчеты',
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: useComponent('payments'),
    meta: {
      title: 'Платежи',
      auth: true,
      menu: true,
      icon: 'credit-card',
      label: 'Платежи',
      menuGroup: 'Платежи',
    },
  },
  {
    path: '/spaces',
    name: 'spaces',
    component: useComponent('Objects/objects'),
    meta: {
      title: 'Площадки',
      auth: true,
      menu: true,
      menuGroup: 'Справочники',
      icon: 'home',
      label: 'Площадки',
    },
  },
  {
    path: '/spaces/:id',
    name: 'space',
    component: useComponent('Objects/object'),
    meta: {
      title: 'Площадка',
      auth: true,
      menu: false,
      parent: 'spaces',
    },
  },
  {
    path: '/addresses',
    name: 'addresses',
    component: useComponent('Addresses/addresses'),
    meta: {
      title: 'Адреса',
      auth: true,
      menu: true,
      menuGroup: 'Справочники',
      icon: 'key',
      label: 'Адреса',
    },
  },
  {
    path: '/addresses/:id',
    name: 'address',
    meta: {
      title: 'Адрес',
      auth: true,
      menu: false,
      parent: 'addresses',
    },
    component: useComponent('Addresses/address'),
    children: [
      {
        path: 'payments',
        name: 'address::payments',
        component: useComponent('payments_address'),
        meta: {
          title: 'Платежи по адресу',
          auth: true,
          menu: false,
          breadcrumbs: ['home'],
        },
      },
      {
        path: 'employees',
        name: 'address::employees',
        component: useChildrenComponent('Addresses/AddressEmployees'),
        meta: {
          title: 'Проживающие сотрудники',
          auth: true,
          menu: false,
        },
      },
    ],
  },
  {
    path: '/employees',
    name: 'employees',
    component: useComponent('Employees/employees'),
    meta: {
      title: 'Сотрудники',
      auth: true,
      menu: true,
      menuGroup: 'Справочники',
      icon: 'briefcase',
      label: 'Сотрудники',
    },
  },
  {
    path: '/departments',
    name: 'departments',
    component: useComponent('departments'),
    meta: {
      title: 'Подразделения',
      auth: true,
      menu: true,
      menuGroup: 'Справочники',
      icon: 'layers',
      label: 'Подразделения',
    },
  },
  {
    path: '/positions',
    name: 'positions',
    component: useComponent('positions'),
    meta: {
      title: 'Должности',
      auth: true,
      menu: true,
      menuGroup: 'Справочники',
      icon: 'chair',
      label: 'Должности',
    },
  },
  {
    path: '/candidates/all',
    name: 'candidates',
    component: useComponent('Candidates/candidates'),
    meta: {
      title: 'Кандидаты',
      auth: true,
      menu: true,
      menuGroup: 'Рекрутинг',
      icon: 'crosshair',
      label: 'Кандидаты',
    },
  },
  {
    path: '/candidates/requests',
    name: 'candidates_requests',
    component: useComponent('Candidates/candidates'),
    meta: {
      title: 'План/Заявки',
      auth: true,
      menu: true,
      menuGroup: 'Рекрутинг',
      icon: 'file-plus',
      label: 'План/Заявки',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: useComponent('profile'),
    meta: {
      title: 'Настройки профиля',
      auth: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: useComponent('admin/users'),
    meta: {
      title: 'Пользователи',
      auth: true,
      menu: true,
      menuGroup: 'Настройки',
      icon: 'users',
      label: 'Пользователи',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: useComponent('admin/roles'),
    meta: {
      title: 'Роли',
      auth: true,
      menu: true,
      menuGroup: 'Настройки',
      icon: 'user-check',
      label: 'Роли',
    },
  },
  {
    path: '/payment_types',
    name: 'payment_types',
    component: useComponent('admin/payment_types'),
    meta: {
      title: 'Типы платежей',
      auth: true,
      menu: true,
      menuGroup: 'Настройки',
      icon: 'list',
      label: 'Типы платежей',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  user: null,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.afterEach((to) => {
  document.title = to.meta.title;
});

const getAppData = () => {
  store.dispatch('getData');
};

router.beforeEach((to, from, next) => {
  const { token, profile } = store.state;

  if (!to.meta.auth) next();
  if (to.meta.auth === true && !token) router.push({ name: 'auth' });

  if (!to.meta.auth && token) {
    router.push({ name: '/' });
  }
  if (to.meta.auth === true && !profile) {
    getAppData();
  }

  next();
});

export default router;
