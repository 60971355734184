<template>
  <el-form
    class="evict-form"
    ref="form"
    :model="form"
    :rules="rules"
    :disabled="load"
    @validate="onValidate"
  >
    <el-form-item
      :label="!disabled?.address_id ? `Адрес` : ''"
      prop="address_id"
    >
      <AddressSelect
        v-model="form.address_id"
        :disabled="!!disabled?.address_id"
      />
    </el-form-item>
    <el-form-item label="Тип платежа" prop="type_id">
      <el-select
        v-model="form.type_id"
        placeholder="Выберите тип платежа"
        :disabled="!!disabled?.type_id"
      >
        <el-option
          v-for="item in types"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Дата платежа" prop="check_out">
            <el-date-picker
              v-model="form.date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              placeholder="10.11.2020"
            >
            </el-date-picker
          ></el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Сумма платежа" prop="cost">
            <el-input
              v-model="form.cost"
              type="Number"
              placeholder="100000"
            /> </el-form-item
        ></el-col> </el-row
    ></el-form-item>
    <el-form-item>
      <el-button
        type="success"
        @click="save"
        v-loading="load"
        :disabled="!isFormValid"
      >
        {{ options.buttonLabel }}
      </el-button></el-form-item
    >
  </el-form>
</template>

<script>
import { mapState } from 'vuex';
import { AddressSelect } from '@/components/UI/AddressSelect';

export default {
  name: 'PaymentForm',
  components: {
    AddressSelect,
  },
  props: {
    content: {
      type: [Object, null],
      default: null,
    },
    disabled: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      form: {
        address_id: null,
        date: null,
        type_id: null,
        cost: null,
      },
      rules: {
        cost: [{ required: true, message: 'Поле не может быть пустым' }],
        address_id: [{ required: true, message: 'Поле не может быть пустым' }],
        type_id: [{ required: true, message: 'Поле не может быть пустым' }],
      },
      isValid: false,
      validFlags: {
        cost: false,
        address_id: false,
        type_id: false,
      },
      load: false,
    };
  },
  created() {
    this.form.date = new Date();

    if (this.content) {
      this.form.address_id = this.content.address_id;
      this.form.cost = this.content.cost;
      this.form.type_id = this.content.type_id;
      console.log(this.content?.date ? true : false);
      if (this.content?.date) this.form.date = this.content?.date;

      for (let key in this.validFlags) this.validFlags[key] = true;
    }

    if (!this.disabled) return;
    if (this.disabled.address_id) {
      this.form.address_id = Number(this.disabled.address_id);
      this.validFlags.address_id = true;
    }
    if (this.disabled.type_id) {
      this.form.type_id = Number(this.disabled.type_id);
      this.validFlags.type_id = true;
    }
  },
  computed: {
    ...mapState('modal', ['options']),
    ...mapState('addresses', ['addresses']),
    ...mapState('payments', ['types']),
    isFormValid() {
      return Object.values(this.validFlags).every((flag) => flag);
    },
  },
  methods: {
    onValidate(field, isValid) {
      this.validFlags[field] = isValid;
    },
    save() {
      this.load = true;
      if (this.content) this.form.id = this.content.id;
      this.$store
        .dispatch('modal/resolve', { data: { ...this.form } })
        .finally(() => (this.load = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
