<template>
  <div class="breadcrumbs">
    <div class="breadcrumbs_one">
      <div class="svg-icon" v-html="$icons.home"></div>
      <div class="svg-icon __arrow" v-html="$icons['chevron-right']"></div>
    </div>
    <div class="breadcrumbs_one" v-for="({ path, name, meta }, ind) in breads" :key="path">
      <router-link
        class="url"
        :to="{ name }"
        :disabled="ind == Object.keys(breads).length - 1 || meta?.parent"
        :event="ind == Object.keys(breads).length - 1 || meta?.parent ? '' : 'click'"
        :class="{
          __disabled: ind == Object.keys(breads).length - 1 || meta?.parent
        }"
      >
        <span>{{ getName(name) }}</span>
      </router-link>
      <div class="svg-icon __arrow" v-html="$icons['chevron-right']" v-if="ind != Object.keys(breads).length - 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  computed: {
    routes() {
      const routes = [...this.$router.options.routes];

      this.$router.options.routes.forEach(route => {
        if (route.children)
          route.children.forEach(el => {
            const routeF = { ...el };
            routeF.parent = route;
            routes.push(routeF);
          });
      });

      return routes;
    },
    breads() {
      const out = [];
      this.$route.matched.forEach(route => {
        if (route?.meta?.parent) out.push(this.routes.find(x => x.name == route?.meta?.parent));
        out.push(route);
      });

      return out;
    }
  },
  methods: {
    getName(name) {
      return this.routes.find(x => x.name == name)?.meta?.title;
    }
  }
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  .breadcrumbs_one::v-deep {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    .svg-icon {
      height: 16px;
      width: 16px;
      color: #505061;
      &.__arrow {
        margin: 0 12px;
      }
      svg {
        fill: #c5c5d88f;
      }
    }
  }
}
</style>
