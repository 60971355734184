<template>
  <el-form
    class="link-address-form"
    ref="form"
    :model="form"
    :rules="rules"
    :disabled="load"
    v-loading="formLoad"
    element-loading-text="Загрузка данных..."
    element-loading-background="rgb(247 248 249 / 80%)"
    @validate="onValidate"
  >
    <el-form-item label="Выберите адрес" prop="address_id">
      <el-select v-model="form.address_id" clearable placeholder="Выберите адрес">
        <el-option v-for="address in addresses" :key="address.id" :label="address.title" :value="address.id" />
      </el-select>
    </el-form-item>
    <div class="link-address-form-info" v-if="form.address_id">
      <span>{{ addressInfo }}</span>
      <span v-if="!isSelectedAddressOfCurrentObject">
        {{ ` При сохранении изменений адрес будет перекреплён к площадке ${currentObject.title}.` }}
      </span>
    </div>
    <el-button
      type="success"
      @click="save"
      v-loading="load"
      :disabled="!isFormValid || isSelectedAddressOfCurrentObject"
    >
      {{ options.buttonLabel }}
    </el-button>
  </el-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LinkAddressForm",
  data() {
    return {
      form: {
        address_id: ""
      },
      rules: {
        address_id: [{ required: true, message: "Поле не может быть пустым" }]
      },
      isValid: false,
      validFlags: {
        address_id: false
      },
      load: false,
      formLoad: false
    };
  },
  computed: {
    ...mapState("modal", ["options"]),
    ...mapState("addresses", ["addresses"]),
    ...mapState("objects", ["currentObject"]),
    isFormValid() {
      return Object.values(this.validFlags).every(flag => flag);
    },
    currentAddress() {
      return this.addresses.find(address => address.id === this.form.address_id) || null;
    },
    isSelectedAddressOfCurrentObject() {
      return this.currentAddress.object_id == this.currentObject.id;
    },
    addressInfo() {
      const currentText = `Этот адрес уже привязан к площадке ${this.currentObject.title}.`;
      const warningText = `Этот адрес уже привязан к площадке "${
        this.currentAddress?.object_directory?.title || "без названия"
      }".`;
      return this.isSelectedAddressOfCurrentObject ? currentText : warningText;
    }
  },
  created() {
    this.fetchAddresses();
  },
  methods: {
    async fetchAddresses() {
      this.formLoad = true;
      await this.$store.dispatch("addresses/fetchAddresses");
      this.formLoad = false;
    },
    onValidate(field, isValid) {
      this.validFlags[field] = isValid;
    },
    save() {
      this.load = true;
      this.$store.dispatch("modal/resolve", { data: this.currentAddress }).finally(() => (this.load = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.link-address-form {
  &-info {
    background-color: rgba(255, 226, 5, 0.2705882353);
    padding: 4px 12px;
    border-radius: 8px;
    margin-bottom: 24px;
  }
}
</style>
