<template>
  <el-tooltip
    class="item"
    effect="dark"
    :content="item.meta.label"
    placement="right"
    :disabled="hasTooltip"
  >
    <router-link :to="{ name: item.name }" class="menu__item">
      <div class="menu__item-icon">
        <div class="svg-icon" v-html="$icons[item.meta.icon]"></div>
      </div>

      <div class="menu__item-label">
        {{ item.meta.label }}
      </div>
      <div class="menu__item-active">
        <div class="svg-icon" v-html="$icons['chevrons-right']"></div>
      </div>
    </router-link>
  </el-tooltip>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    item: {
      type: Object,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  height: 42px;
  transition: 0.25s;
  padding: 0 8px 0 12px;
  cursor: pointer;
  border-radius: 4px;
  @media screen and (max-width: 1360px) {
    padding: 0 6px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & + .menu__item {
    margin-top: 4px;
  }
  &-active::v-deep {
    opacity: 0;
    margin-left: auto;
    height: 17px;
    color: #c5c5d8;
    svg {
      fill: #505061;
    }

    @media screen and (max-width: 1360px) {
      display: none;
    }
  }
  &:hover {
    background-color: #13131c;
  }
  &.router-link-active {
    background-color: #13131c;
    .menu__item-active {
      opacity: 1;
    }
  }
  &-icon::v-deep {
    width: 18px;
    height: 18px;
    margin-right: 12px;
    color: #c5c5d8;
    @media screen and (max-width: 1360px) {
      margin-right: 0px;
    }
    svg {
      fill: #505061;
    }
  }
  &-label {
    font-size: 14px;
    font-weight: 400;
    color: #9d9da6;
    @media screen and (max-width: 1360px) {
      display: none;
    }
  }
}

// .menu__item {
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   align-content: center;
//   justify-content: flex-start;
//   align-items: center;
//   height: 42px;
//   transition: 0.25s;
//   padding: 0 8px 0 12px;
//   cursor: pointer;
//   border-radius: 4px;
//   & + .menu__item {
//     margin-top: 4px;
//   }
//   &-active::v-deep {
//     opacity: 0;
//     margin-left: auto;
//     height: 17px;
//     color: #a1a5b7;
//     svg {
//       fill: #cfd0d7;
//     }
//   }
//   &:hover {
//     background-color: #f5f5f5;
//   }
//   &.router-link-active {
//     background-color: #f5f5f5;
//     .menu__item-active {
//       opacity: 1;
//     }
//   }
//   &-icon::v-deep {
//     width: 18px;
//     height: 18px;
//     margin-right: 12px;
//     color: #a1a5b7;
//     svg {
//       fill: #cfd0d7;
//     }
//   }
//   &-label {
//     font-size: 14px;
//     font-weight: 400;
//     color: #5e6278;
//   }
// }
</style>
