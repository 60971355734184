<template>
  <div
    class="employee-form"
    v-loading="formLoad"
    element-loading-text="Загрузка данных..."
    element-loading-background="rgb(247 248 249 / 80%)"
  >
    <el-form ref="form" :model="form" :rules="rules" :disabled="load" @validate="onValidate">
      <el-row :gutter="20" label="ФИО">
        <el-col :span="8">
          <el-form-item label="Фамилия" prop="surname">
            <el-input v-model="form.surname" placeholder="Фамилия"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Имя" prop="name">
            <el-input v-model="form.name" placeholder="Имя"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Отчество" prop="fathername">
            <el-input v-model="form.fathername" placeholder="Отчество"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Телефон" prop="phone">
        <el-input v-model="form.phone" placeholder="Телефон"></el-input>
      </el-form-item>
      <el-form-item label="Электронная почта" prop="email">
        <el-input v-model="form.email" placeholder="Электронная почта"></el-input>
      </el-form-item>
    </el-form>
    <el-divider />
    <div class="employee-form-settle-info" v-if="content?.accounting_list[0]?.check_out">
      <el-row class="employee-form-settle-info-row">
        <h3 class="employee-form-settle-info-row-title">Последнее заселение</h3>
      </el-row>
      <el-row class="employee-form-settle-info-row">
        <el-col :span="12" class="employee-form-settle-info-label"> Адрес: </el-col>
        <el-col :span="12" class="employee-form-settle-info-value">
          {{ content?.accounting_list[0]?.address_info?.title || "–" }}
        </el-col>
      </el-row>
      <el-row class="employee-form-settle-info-row">
        <el-col :span="12" class="employee-form-settle-info-label"> Дата заселения: </el-col>
        <el-col :span="12" class="employee-form-settle-info-value">
          {{ content?.accounting_list[0]?.check_in || "–" | moment("DD.MM.YYYY") }}
        </el-col>
      </el-row>
      <el-row class="employee-form-settle-info-row">
        <el-col :span="12" class="employee-form-settle-info-label"> Дата выселения: </el-col>
        <el-col :span="12" class="employee-form-settle-info-value">
          {{ content?.accounting_list[0]?.check_out || "–" | moment("DD.MM.YYYY") }}
        </el-col>
      </el-row>
    </div>
    <div class="employee-form-settle-info" v-else-if="content?.accounting_list[0]?.check_in">
      <el-row class="employee-form-settle-info-row">
        <h3>Сотрудник заселён</h3>
      </el-row>
    </div>
    <el-form ref="formSettle" :model="formSettle" :rules="isSettleRequired ? formSettleRules : {}" :disabled="load">
      <h3>
        {{ content?.accounting_list[0]?.check_out ? "Заселение" : "Данные о заселении" }}
      </h3>
      <el-form-item label="Выберите адрес" prop="address_id">
        <el-select
          v-model="formSettle.address_id"
          clearable
          placeholder="Выберите адрес"
          :disabled="!!disabled?.address_id"
        >
          <el-option
            v-for="address in addresses"
            :key="address.id"
            :label="address.title"
            :value="address.id"
            :disabled="address?.positions - (address?.residents_stats?.residents_count || 0) <= 0"
          >
            <span style="float: left">{{ address.title }}</span>
            <span
              style="float: right; color: #8492a6; font-size: 12px; text-transform: lowercase"
              v-if="address?.positions - (address?.residents_stats?.residents_count || 0) <= 0"
            >
              Нет мест
            </span>
            <span style="float: right; color: #8492a6; font-size: 12px; text-transform: lowercase" v-else>
              Cвободно:
              {{ address?.positions - (address?.residents_stats?.residents_count || 0) }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Дата заселения" prop="check_in">
        <el-date-picker v-model="formSettle.check_in" format="dd.MM.yyyy" placeholder="Дата заселения">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <el-button type="success" @click="save" v-loading="load" :disabled="!isFormValid || (!changed && !settleChanged)">
      {{ options.buttonLabel }}
    </el-button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EmployeeForm",
  props: {
    content: {
      type: [Object, null],
      default: null
    },
    disabled: {
      type: [Object, null],
      default: null
    }
  },
  data() {
    return {
      form: {
        name: "",
        surname: "",
        fathername: "",
        phone: "",
        email: ""
      },
      rules: {
        name: [{ required: true, message: "Поле не может быть пустым" }],
        surname: [{ required: true, message: "Поле не может быть пустым" }],
        email: [{ validator: this.validateEmail, trigger: "change" }]
      },
      formSettle: {
        address_id: "",
        check_in: ""
      },
      formSettleRules: {
        check_in: [{ required: true, message: "Поле не может быть пустым" }],
        address_id: [{ required: true, message: "Поле не может быть пустым" }]
      },
      isValid: false,
      validFlags: {
        name: false,
        surname: false,
        email: true
      },
      load: false,
      changed: true,
      settleChanged: true,
      formLoad: false
    };
  },
  computed: {
    ...mapState("modal", ["options"]),
    ...mapState("addresses", ["addresses"]),
    isFormValid() {
      const commonFlag = Object.values(this.validFlags).every(flag => flag);
      return this.isSettleRequired ? commonFlag && this.isSettleFilled : commonFlag;
    },
    isSettleRequired() {
      const req = !!this.formSettle.address_id || !!this.formSettle.check_in;
      if (!req) this.$refs?.formSettle?.resetFields();
      return req;
    },
    isSettleFilled() {
      return !!this.formSettle.address_id && !!this.formSettle.check_in;
    }
  },
  created() {
    this.checkAddress();
    if (!this.content) return;
    this.changed = false;
    this.settleChanged = false;
    Object.entries(this.content).forEach(([key, value]) => {
      if (this.form[key] !== undefined) this.form[key] = value;
    });
    if (!this.content.accounting_list[0]?.check_out) {
      this.formSettle.address_id = this.content.accounting_list[0].address_id;
      this.formSettle.check_in = this.content.accounting_list[0].check_in;
    }
    this.$watch("form", () => (this.changed = true), { deep: true });
    this.$watch("formSettle", () => (this.settleChanged = true), {
      deep: true
    });
  },
  watch: {
    "form.email"(v) {
      if (!v) this.validFlags.email = true;
    }
  },
  mounted() {
    if (this.content) this.$refs.form.validate();
  },
  methods: {
    onValidate(field, isValid) {
      this.validFlags[field] = isValid;
    },
    save() {
      this.load = true;
      if (this.content) {
        this.form.id = this.content.id;
        this.formSettle.accounting_id = this.content.accounting_list[0].id;
      } else if (!this.disabled) this.form.new = true;
      this.formSettle.check_in = this.$moment(this.formSettle.check_in).format("YYYY-MM-DD");
      const result = this.isSettleFilled && this.settleChanged ? { ...this.form, ...this.formSettle } : this.form;
      this.$store.dispatch("modal/resolve", { data: result }).finally(() => (this.load = false));
    },
    validateEmail: (_, value, callback) => {
      const pattern = /.+@.+\..+/i;
      if (!value) return true;
      if (!pattern.test(value)) {
        callback(new Error("Email введен некорректно"));
        return false;
      }
      callback();
      return true;
    },
    async checkAddress() {
      this.formLoad = true;
      await this.$store.dispatch("addresses/fetchAddresses");
      if (this.disabled?.address_id) {
        this.formSettle.address_id = Number(this.disabled.address_id);
        this.validFlags.address_id = true;
        this.$nextTick(this.$refs?.formSettle?.clearValidate);
      }
      this.formLoad = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.employee-form {
  &-settle-info {
    background-color: rgba(255, 226, 5, 0.2705882353);
    border-radius: 8px;

    &-row {
      padding: 4px 12px;

      & > &-title {
        border-bottom: 1px solid black;
      }
    }

    &-label {
      font-weight: 600;
    }

    &-value {
      text-align: right;
    }
  }
}
.employee-form::v-deep {
  .el-form-item__label {
    text-align: left;
  }

  .el-button {
    margin-top: 20px;
  }
}
</style>
