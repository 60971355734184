import Vue from "vue";

const Download = {
  install(Vue) {
    Vue.prototype.$givmehash = function (length = 6) {
      const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP";
      let hash = "";
      for (let x = 0; x < length; x++) {
        const i = Math.floor(Math.random() * chars.length);
        hash += chars.charAt(i);
      }
      return hash;
    };
  }
};

Vue.use(Download);
