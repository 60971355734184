import Vue from 'vue';
import App from '@/App';

import '@/plugins/index';

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU';
//import locale from 'element-ui/lib/locale';

import '@/assets/styles/index.scss';

import router from '@/router';
import store from '@/store';

const moment = require('moment');
require('moment/locale/ru');

Vue.use(require('vue-moment'), {
  moment,
});

//locale.use(lang);
Vue.use(ElementUI, { locale });
/* eslint-disable no-new */

Vue.use(ElementUI, { locale });

import VueMask from 'v-mask';
Vue.use(VueMask);

Vue.config.devtools = true;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
