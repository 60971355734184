<template>
  <el-form
    class="settle-form"
    ref="form"
    :model="form"
    :rules="rules"
    :disabled="load"
    v-loading="formLoad"
    element-loading-text="Загрузка данных..."
    element-loading-background="rgb(247 248 249 / 80%)"
    @validate="onValidate"
  >
    <el-form-item label="Выберите сотрудника" prop="employee_id">
      <el-select v-model="form.employee_id" filterable placeholder="Выберите сотрудника">
        <el-option
          v-for="employee in employees"
          :key="employee.id"
          :label="getName(employee)"
          :value="employee.id"
          :disabled="isEmployeeAlreadySettled(employee)"
        >
          <span style="float: left">{{ getName(employee) }}</span>
          <span
            style="float: right; color: #8492a6; font-size: 12px; text-transform: lowercase"
            v-if="isEmployeeAlreadySettled(employee)"
          >
            заселён
          </span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Дата заселения" prop="check_in">
      <el-date-picker v-model="form.check_in" format="dd.MM.yyyy" placeholder="Дата заселения"> </el-date-picker>
    </el-form-item>
    <el-button type="success" @click="save" v-loading="load" :disabled="!isFormValid">
      {{ options.buttonLabel }}
    </el-button>
  </el-form>
</template>

<script>
import { mapState } from "vuex";
import getName from "@/helpers/getName";

export default {
  name: "SettleForm",
  data() {
    return {
      form: {
        employee_id: "",
        check_in: ""
      },
      rules: {
        employee_id: [{ required: true, message: "Поле не может быть пустым" }],
        check_in: [{ required: true, message: "Поле не может быть пустым" }]
      },
      isValid: false,
      validFlags: {
        employee_id: false,
        check_in: false
      },
      load: false,
      formLoad: false
    };
  },
  computed: {
    ...mapState("modal", ["options"]),
    ...mapState("employees", ["employees"]),
    isFormValid() {
      return Object.values(this.validFlags).every(flag => flag);
    }
  },
  created() {
    this.fetchEmployees();
  },
  methods: {
    getName,
    async fetchEmployees() {
      this.formLoad = true;
      await this.$store.dispatch("employees/fetchEmployees");
      this.formLoad = false;
    },
    isEmployeeAlreadySettled(employee) {
      return employee.accounting_list?.[0]?.check_in && !employee.accounting_list[0].check_out;
    },
    onValidate(field, isValid) {
      this.validFlags[field] = isValid;
    },
    save() {
      this.load = true;
      this.form.check_in = this.$moment(this.form.check_in).format("YYYY-MM-DD");
      this.$store.dispatch("modal/resolve", { data: this.form }).finally(() => (this.load = false));
    }
  }
};
</script>

<style lang="scss" scoped></style>
