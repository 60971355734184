<template>
  <el-form
    class="address-form"
    ref="form"
    :model="form"
    :rules="isNeedDepositFields ? { ...rules, ...deposit_rules } : rules"
    :disabled="load"
    :validate-on-rule-change="false"
    @validate="onValidate"
    v-loading="formLoad"
    element-loading-text="Загрузка данных..."
    element-loading-background="rgb(247 248 249 / 80%)"
  >
    <el-form-item label="Адрес" prop="title">
      <el-input v-model="form.title" placeholder="Адрес"></el-input>
    </el-form-item>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Начало аренды" prop="start_date">
          <el-date-picker v-model="form.start_date" format="dd.MM.yyyy" placeholder="Начало аренды"> </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Площадка" prop="object_id">
          <el-select v-model="form.object_id" placeholder="Выберите площадку" :disabled="!!disabled?.object_id">
            <el-option v-for="obj in objects" :key="obj.id" :label="obj.title" :value="obj.id" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Количество мест" prop="positions">
          <el-input v-model="form.positions" type="number" placeholder="Количество мест"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-divider />
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Сумма оплаты" prop="rent_sum">
          <el-input v-model="form.rent_sum" type="number" placeholder="Сумма оплаты"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Число месяца для оплаты" prop="pay_day">
          <el-input
            v-model="form.pay_day"
            type="number"
            min="1"
            max="28"
            placeholder="Число месяца для оплаты"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="!content">
      <el-col :span="12">
        <el-form-item label="Сумма депозита" prop="collateral">
          <el-input v-model="form.collateral" type="number" placeholder="Депозит"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Дата внесения депозита" prop="collateral_date">
          <el-date-picker v-model="form.collateral_date" format="dd.MM.yyyy" placeholder="Дата депозита">
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>

    <el-divider />
    <el-form-item label="Имя владельца" prop="owner_name">
      <el-input v-model="form.owner_name" placeholder="Имя владельца"></el-input>
    </el-form-item>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Телефон владельца" prop="owner_phone">
          <el-input v-model="form.owner_phone" placeholder="Телефон"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Электронная почта владельца" prop="owner_email">
          <el-input v-model="form.owner_email" placeholder="Электронная почта"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Банк владельца" prop="owner_bank">
          <el-input v-model="form.owner_bank" placeholder="Наименование банка"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Реквизиты владельца" prop="owner_requisites">
          <el-input v-model="form.owner_requisites" placeholder="Банковские реквизиты"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="Комментарий" prop="comment">
      <el-input v-model="form.comment" type="textarea" placeholder="Комментарий"></el-input>
    </el-form-item>
    <el-button type="success" @click="save" v-loading="load" :disabled="!isFormValid || !changed">
      {{ options.buttonLabel }}
    </el-button>
  </el-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AddressForm",
  props: {
    content: {
      type: [Object, null],
      default: null
    },
    disabled: {
      type: [Object, null],
      default: null
    }
  },
  data() {
    return {
      selectObject: null,
      formLoad: false,
      form: {
        title: "",
        object_id: "",
        start_date: "",
        rent_sum: "",
        collateral: "",
        collateral_date: null,
        pay_day: "",
        positions: "",
        owner_name: "",
        owner_phone: "",
        owner_email: "",
        owner_bank: "",
        owner_requisites: "",
        comment: ""
      },
      rules: {
        title: [{ required: true, message: "Поле не может быть пустым" }],
        object_id: [{ required: true, message: "Поле не может быть пустым" }],
        positions: [{ required: true, message: "Поле не может быть пустым" }],
        start_date: [{ required: true, message: "Поле не может быть пустым" }],
        rent_sum: [{ required: true, message: "Поле не может быть пустым" }],
        pay_day: [
          { required: true, message: "Поле не может быть пустым" },
          { validator: this.validatePayDay, trigger: "change" }
        ],
        owner_email: [{ validator: this.validateEmail, trigger: "change" }]
      },
      deposit_rules: {
        collateral: [{ required: true, message: "Поле не может быть пустым" }],
        collateral_date: [{ required: true, message: "Поле не может быть пустым" }]
      },
      isValid: false,
      validFlags: {
        title: false,
        object_id: false,
        positions: false,
        start_date: false,
        rent_sum: false,
        collateral: true,
        collateral_date: true,
        pay_day: false,
        owner_email: true
      },
      load: false,
      changed: true
    };
  },
  computed: {
    ...mapState("modal", ["options"]),
    ...mapState("objects", ["objects"]),
    isFormValid() {
      return Object.values(this.validFlags).every(flag => flag);
    },
    isNeedDepositFields() {
      return !!this.form.collateral || !!this.form.collateral_date;
    }
  },
  created() {
    this.checkObjects();
    if (!this.content) return;
    this.changed = false;

    Object.entries(this.content).forEach(([key, value]) => {
      if (this.form[key] !== undefined) this.form[key] = value;
    });
    this.$watch("form", () => (this.changed = true), { deep: true });
  },
  watch: {
    isNeedDepositFields(isNeed) {
      this.validFlags.collateral = !isNeed;
      this.validFlags.collateral_date = !isNeed;
      this.$refs?.form.clearValidate(["collateral_date", "collateral"]);
    },
    "form.owner_email"(v) {
      if (!v) this.validFlags.owner_email = true;
    }
  },
  mounted() {
    if (this.content) {
      this.$refs.form.validate();
      this.$nextTick(() => {
        this.validFlags.collateral = true;
        this.validFlags.collateral_date = true;
      });
    }
  },
  methods: {
    onValidate(field, isValid) {
      this.validFlags[field] = isValid;
    },
    save() {
      this.load = true;
      if (this.content) this.form.id = this.content.id;
      this.form.start_date = this.$moment(this.form.start_date).format("YYYY-MM-DD");
      if (!this.form.collateral) this.form.collateral = null;
      if (!this.form.collateral_date) this.form.collateral_date = null;
      const paymentDataChanged =
        this.content?.pay_day != this.form.pay_day || this.content.rent_sum != this.form.rent_sum;
      if (this.content && paymentDataChanged) {
        this.form.payDayChanged = true;
      }
      this.$store.dispatch("modal/resolve", { data: this.form }).finally(() => (this.load = false));
    },
    validatePayDay: (_, value, callback) => {
      if (value < 1 || value > 28) {
        callback(new Error("Должно быть число месяца от 1 до 28"));
        return false;
      }
      callback();
      return true;
    },
    validateEmail: (_, value, callback) => {
      const pattern = /.+@.+\..+/i;
      if (!value) return true;
      if (!pattern.test(value)) {
        callback(new Error("Email введен некорректно"));
        return false;
      }
      callback();
      return true;
    },
    async checkObjects() {
      this.formLoad = true;
      await this.$store.dispatch("objects/fetchObjects");
      if (this.disabled?.object_id) {
        this.form.object_id = Number(this.disabled.object_id);
        this.validFlags.object_id = true;
        this.$nextTick(this.$refs?.form?.clearValidate);
      }
      this.formLoad = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.address-form::v-deep {
  .el-form-item__label {
    text-align: left;
  }

  .el-row {
    margin-bottom: 8px;
  }
}
</style>
