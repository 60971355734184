import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import modal from './modal';
import auth from './auth';
import users from './users';

export default new Vuex.Store({
  modules: {
    modal,
    auth,
    users,
  },
  state: {
    profile: null,
    token: localStorage.getItem('token') || null,
    isLoading: false,
  },
  mutations: {
    SET_TOKEN: (state, { token }) => {
      state.token = token;
      if (token) {
        localStorage.setItem('token', token);
        return;
      }

      localStorage.removeItem('token');
      location.reload();
    },
    SET_LOAD: (state, payload) => (state.isLoading = payload),
    SET_PROFILE: (state, payload) => (state.profile = payload),
  },
  actions: {
    setLoad: ({ commit }, payload) => commit('SET_LOAD', payload),
    async getData({ dispatch }) {
      await dispatch('auth/getProfile');
      await dispatch('users/fetchRoles');
    },
    setToken: ({ commit }, payload) => commit('SET_TOKEN', payload),
    setProfile: ({ commit }, payload) => commit('SET_PROFILE', payload),
    logout({ commit }) {
      commit('SET_TOKEN', { token: null });
      this._vm.$router.push({ name: 'auth' });
    },
  },

  getters: {
    permissions(state) {
      if (!state?.profile) return false;
      if (!state?.users?.roles) return false;

      const { roles } = state.profile;

      const perm = {};
      roles.forEach((role) => {
        const drole = state.users.roles.find((x) => x.key == role);
        if (!drole) return;
        for (let key in drole.config) {
          const trig = drole.config[key] == 1 ? true : false;
          perm[key] = perm[key] || trig;
        }
      });
      return perm;
    },
  },
});
