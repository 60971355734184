<template>
  <div id="app">
    <Loader v-if="(load || !profile) && $route?.meta?.auth" />
    <component v-else :is="layout"> </component>
    <Online />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as Layouts from '@/layouts';

import { Loader } from '@/components/Loader';
import { Online } from '@/components/UI/Online';

export default {
  name: 'App',
  components: {
    ...Layouts,
    Loader,
    Online,
  },
  data() {
    return {
      load: true,
    };
  },
  mounted() {
    const time = this.$route.meta.auth ? 1550 : 0;
    setTimeout(() => {
      this.load = false;
    }, time);
  },
  computed: {
    ...mapState(['token', 'profile']),
    layout() {
      if (!this.$route.name) return null;
      const name = this.capitalizeFirstLetter(
        this.$route.meta.layout || 'default'
      );
      return `Layout${name}`;
    },
  },
  methods: {
    capitalizeFirstLetter(s) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped></style>
