<template>
  <div class="menu">
    <div class="menu__logo">
      <div class="svg-icon" v-html="$icons.logo"></div>
    </div>
    <div class="menu__items" v-for="group in groups" :key="group.label">
      <h3 v-if="group?.label">{{ group.label }}</h3>
      <MenuItem
        v-for="item in group.items"
        :key="item.path"
        :item="item"
        :has-tooltip="hasTooltip"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MenuItem } from './MenuItem';

export default {
  name: 'Menu',
  components: {
    MenuItem,
  },
  created() {
    window.addEventListener('resize', () => {
      console.log('resize');
      this.hasTooltip = window.outerWidth > 1360;
    });
  },
  data() {
    return {
      hasTooltip: false,
    };
  },
  computed: {
    ...mapGetters(['permissions']),
    groups() {
      const { routes } = this.$router.options;
      const items = routes.filter(
        (x) => x?.meta?.menu && this?.permissions[x.name]
      );
      const groups = [...new Set(items.map((x) => x?.meta?.menuGroup || null))];

      const links = groups.map((el) => {
        return {
          label: el,
          items: items.filter((x) => x.meta.menuGroup == el),
        };
      });

      return links;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: var(--width-menu);
  background-color: #ffffff;
  background-color: #1e1e2d;
  padding: 24px 16px;
  box-shadow: 1px 0 0px rgba(0, 0, 0, 0.06);
  height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
  left: 0;
  flex-shrink: 0;
  @media screen and (max-width: 1360px) {
    padding: 24px 8px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: stretch;
    justify-content: flex-start;
    h3 {
      color: #646477;
      font-size: 11px;
      text-transform: uppercase;
      margin: 0 0 8px;
      font-weight: 600;
      @media screen and (max-width: 1360px) {
        display: none;
      }
    }
    & + .menu__items {
      margin-top: 24px;
      @media screen and (max-width: 1360px) {
        margin-top: 12px;
        border-top: 1px solid #50506159;
        padding-top: 12px;
      }
    }
  }
  &__logo::v-deep {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    border-bottom: 1px dashed #393945;
    width: 100%;
    height: 37px;

    .svg-icon {
      height: 24px;
      position: relative;
      top: -11px;
      width: 111px;
      svg {
        fill: #fff;
      }
    }
  }
}
</style>
