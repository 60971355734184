const feather = require('feather-icons');
import Vue from 'vue';
import * as SVG from './svg';

const Icons = {
  install(Vue) {
    var icons = Object.values(feather.icons)
      .map((el) => el.name)
      .reduce((accomulator, key) => {
        accomulator[key] = feather.icons[key].toSvg();
        return accomulator;
      }, {});

    Object.assign(icons, SVG);

    Vue.prototype.$icons = icons;
  },
};

Vue.use(Icons);
