<template>
  <el-form class="evict-form" ref="form" :model="form" :rules="rules" :disabled="load" @validate="onValidate">
    <el-form-item label="Дата выселения" prop="check_out">
      <el-date-picker v-model="form.check_out" format="dd.MM.yyyy" placeholder="Дата выселения"> </el-date-picker>
    </el-form-item>
    <el-button type="success" @click="save" v-loading="load" :disabled="!isFormValid">
      {{ options.buttonLabel }}
    </el-button>
  </el-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SettleForm",
  data() {
    return {
      form: {
        check_out: ""
      },
      rules: {
        check_out: [{ required: true, message: "Поле не может быть пустым" }]
      },
      isValid: false,
      validFlags: {
        check_out: false
      },
      load: false
    };
  },
  computed: {
    ...mapState("modal", ["options"]),
    isFormValid() {
      return Object.values(this.validFlags).every(flag => flag);
    }
  },
  methods: {
    onValidate(field, isValid) {
      this.validFlags[field] = isValid;
    },
    save() {
      this.load = true;
      this.form.check_out = this.$moment(this.form.check_out).format("YYYY-MM-DD");
      this.$store.dispatch("modal/resolve", { data: this.form }).finally(() => (this.load = false));
    }
  }
};
</script>

<style lang="scss" scoped></style>
